import * as React from 'react';
import './gallery.css';

interface GalleryProps {
    imagesArray: any;
    thumbImagesArray: any;
}

export const importAll = (r: __WebpackModuleApi.RequireContext) => {
    return r.keys().map(r);
}

const Gallery = (props: GalleryProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const { imagesArray, thumbImagesArray } = props;

    const handleKeyDown = (event: { key: string; }) => {
        if (event.key === "Escape") {
            setIsOpen(false);
        }

        if (event.key === "ArrowRight") {
            incrementIndex();
        }

        if (event.key === "ArrowLeft") {
            decrementIndex();
        }
    };

    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex]);

    const handleClick = (event: { target: { className: string; }; }) => {
        const isNextOrPrev = event.target.className === 'next' ||
            event.target.className === 'prev';
        if (isOpen && !isNextOrPrev) {
            setIsOpen(false);
        }
    };

    React.useEffect(() => {
        // @ts-ignore
        window.addEventListener('click', handleClick);

        return () => {
            // @ts-ignore
            window.removeEventListener('click', handleClick);
        };
    }, [isOpen]);

    const openModal = (index: React.SetStateAction<number>) => {
        setIsOpen(true);
        setCurrentIndex(index);
    };

    const getImages = () => {
        const images = thumbImagesArray.map((value: any, index: any) => {
            return (
                // <div
                //     key={`img_${index}`}
                //     className='column-gallery'
                // >
                <div key={`img_${index}`} className="image">
                    <img
                        src={value.default}
                        className={'hover-shadow cursor'}
                        onClick={() => { openModal(index) }}
                    />
                </div>
            );
        });

        return (
            <section className="gallery">{images}</section>
        );
    };

    const incrementIndex = () => {
        const newIndex = currentIndex + 1;
        if (newIndex > thumbImagesArray.length - 1) {
            return;
        }
        setCurrentIndex((index) => index + 1);
    };

    const decrementIndex = () => {
        const newIndex = currentIndex - 1;
        if (newIndex < 0) {
            return;
        }
        setCurrentIndex((index) => index - 1);
    };

    const getModal = () => {
        const source = (imagesArray[currentIndex] as any).default;
        // const photographer = /.+\/(.+)_/.exec(source)?.[1];

        return (
            <div
                id="myModal"
                className={'modal'}
                style={{ display: 'block' }}
            >
                <div className="modal-content">
                    <div className='modal-imgs'>
                        <div className='mySlides'>
                            <img src={source} />
                        </div>
                    </div>

                    {currentIndex >= 0 && <a className="prev" onClick={decrementIndex}>&#10094;</a>}
                    {currentIndex < imagesArray.length - 1 && <a className="next" onClick={incrementIndex}>&#10095;</a>}
                    {/* <div className="caption-container">
                        <p id="caption">Credit: {photographer}</p>
                    </div> */}
                </div>
            </div>
        );
    };

    return (
        <>
            {getImages()}
            {isOpen && getModal()}
        </>
    );
};

export default Gallery;
