import * as React from 'react';
import Gallery, { importAll } from '../Gallery';

export const Florals = () => {
    const thumbImagesArray = React.useMemo(() => importAll(require.context('../../imgs/florals/thumbnails', false, /\.(png|jpe?g|svg)$/i)), []);
    const imagesArray = React.useMemo(() => importAll(require.context('../../imgs/florals', false, /\.(png|jpe?g|svg)$/i)), []);

    return <>
        {/* <h1>Florals</h1> */}
        <div className='services'>
            <div className="column-about">
                <div className='gar2'>Florals</div>
                <div className="fifty-pct">
                    Florals are priced based on a clients specific needs - exact pricing is provided after we have a chance to go over the details and floral design of your event.
                </div>
                <br></br>
                <div className="fifty-pct">
                    As a guide we recommend our couples to budget about 10% of their wedding budget for a full floral package which includes:
                </div>
                <br></br>
                <div className='service-breakdown'>bouquets</div>
                <div className='service-breakdown'>boutonnieres</div>
                <div className='service-breakdown'>corsages</div>
                <div className='service-breakdown'>ceremony decor</div>
                <div className='service-breakdown'>reception centerpieces</div>
                <br></br>
                Booking a full floral package with us requires a minimum investment of $7K.
            </div>
        </div>
        <Gallery thumbImagesArray={thumbImagesArray} imagesArray={imagesArray} />
    </>;
};
