import * as React from 'react';
import Gallery, { importAll } from '../Gallery';

export const Doc = () => {
    const thumbImagesArray = React.useMemo(() => importAll(require.context('../../imgs/doc/thumbnails', false, /\.(png|jpe?g|svg)$/i)), []);
    const imagesArray = React.useMemo(() => importAll(require.context('../../imgs/doc', false, /\.(png|jpe?g|svg)$/i)), []);

    return <>
        {/* <h1>Doc</h1> */}
        <div className='services'>
            <div className="column-about">
                <div className='gar2'>Day of Coordination</div>
                <div className="fifty-pct">
                    Our day coordination services ensure a stress-free and seamless celebration for our couples. We help you with managing timelines, coordinating vendors, and handling unexpected hiccups, allowing you and your loved ones to savor every moment. From overseeing setup details to troubleshooting, our expertise ensures a smoothly executed event, letting you focus on creating lasting memories.
                </div>
                <br></br>
                <div className="fifty-pct">
                    Our team speaks English, Cantonese, and Mandarin, and are well versed in Chinese, Filipino, Hindu, Korean, and Vietnamese traditions to help make your day run smoothly with your parents, aunties, uncles, and everyone in between!
                </div>
            </div>
        </div>
        <Gallery thumbImagesArray={thumbImagesArray} imagesArray={imagesArray} />
    </>;
};
