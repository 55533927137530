import * as React from 'react';

const Contact = () => {
    return (
        <iframe
			src="https://docs.google.com/forms/d/e/1FAIpQLSex_kOm3ehDQeLZ4CjVCqtg0-PLJZWmDO3_C_aAzIlpWCvoDg/viewform">
		</iframe>
    );
};

export default Contact;
