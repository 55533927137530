import * as React from 'react';
import Gallery, { importAll } from '../Gallery';

export const Planning = () => {
    const thumbImagesArray = React.useMemo(() => importAll(require.context('../../imgs/planning/thumbnails', false, /\.(png|jpe?g|svg)$/i)), []);
    const imagesArray = React.useMemo(() => importAll(require.context('../../imgs/planning', false, /\.(png|jpe?g|svg)$/i)), []);

    return <>
        {/* <h1>Planning</h1> */}
        <div className='services'>

            <div className='gar2'>Planning</div>
            <div className="column-about">
                <div className="fifty-pct">
                    Have a handful of inspo photos and 5 colors you want to incorporate, but not sure where to start to start bringing your pinterest board to life?

                    <br></br>We’ll help you narrow down the choices and make selections that will best fit your venue and theme.
                </div>
            </div>
        </div>
        <Gallery thumbImagesArray={thumbImagesArray} imagesArray={imagesArray} />
    </>;
};
